import React, { useMemo } from 'react';
import InputController from '@components/form/InputController';
import useIntl from '@i18n/useIntl';
import DropdownController from '@components/form/DropdownController';
import * as formConstants from '@components/form/constants';
import * as accountInfoConstants from '@features/account-settings/constants';
import { ControllersWrapper } from '@components/form/style';

interface AdspAccountDetailsControllersProps {
  industries: Array<{ value: string; label: string }>;
  currencies: Array<{ value: string; label: string }>;
  timeZones: Array<{ value: string; label: string }>;
}

const AdspAccountDetailsControllers = ({
  industries,
  currencies,
  timeZones,
}: AdspAccountDetailsControllersProps) => {
  const { intl } = useIntl();

  const requiredValidation = useMemo(
    () => ({
      required: intl.getMessage(
        formConstants.REQUIRED_VALIDATION_ERROR_MESSAGE_TRANSLATION_KEY,
      ),
    }),
    [intl],
  );

  return (
    <ControllersWrapper>
      <InputController
        label={intl.getMessage(
          accountInfoConstants.ACCOUNT_NAME_FIELD_EDIT_LABEL,
        )}
        id={accountInfoConstants.ACCOUNT_NAME_FIELD_ID}
        controllerName={accountInfoConstants.ACCOUNT_NAME_FIELD_CONTROLLER_NAME}
        registerOptions={{
          required: intl.getMessage(
            formConstants.REQUIRED_VALIDATION_ERROR_MESSAGE_TRANSLATION_KEY,
          ),
        }}
        helpTipText={intl.getMessage(
          accountInfoConstants.ACCOUNT_NAME_FIELD_TOOLTIP_TRANSLATION_KEY,
        )}
      />
      <DropdownController
        label={intl.getMessage(
          accountInfoConstants.ACCOUNT_INDUSTRY_FIELD_LABEL,
        )}
        id={accountInfoConstants.ACCOUNT_INDUSTRY_FIELD_ID}
        controllerName={
          accountInfoConstants.ACCOUNT_INDUSTRY_FIELD_CONTROLLER_NAME
        }
        items={industries}
        registerOptions={{
          ...requiredValidation,
        }}
        overrideDropdownLabel={`${intl.getMessage(
          accountInfoConstants.REGISTRATION_DROPDOWN_DEFAULT_LABEL_PREFIX,
        )} 
        ${intl
          .getMessage(accountInfoConstants.ACCOUNT_INDUSTRY_FIELD_LABEL)
          .toLocaleLowerCase()}
        `}
      />
      <DropdownController
        label={intl.getMessage(
          accountInfoConstants.ACCOUNT_TIME_ZONE_FIELD_LABEL,
        )}
        id={accountInfoConstants.ACCOUNT_TIME_ZONE_FIELD_ID}
        controllerName={
          accountInfoConstants.ACCOUNT_TIME_ZONE_FIELD_CONTROLLER_NAME
        }
        items={timeZones}
        registerOptions={{
          ...requiredValidation,
        }}
        overrideDropdownLabel={`${intl.getMessage(
          accountInfoConstants.REGISTRATION_DROPDOWN_DEFAULT_LABEL_PREFIX,
        )} 
        ${intl
          .getMessage(accountInfoConstants.ACCOUNT_TIME_ZONE_FIELD_LABEL)
          .toLocaleLowerCase()}
        `}
      />
      <DropdownController
        label={intl.getMessage(
          accountInfoConstants.ACCOUNT_CURRENCY_FIELD_LABEL,
        )}
        id={accountInfoConstants.ACCOUNT_CURRENCY_FIELD_ID}
        controllerName={
          accountInfoConstants.ACCOUNT_CURRENCY_FIELD_CONTROLLER_NAME
        }
        items={currencies}
        registerOptions={{
          ...requiredValidation,
        }}
        overrideDropdownLabel={`${intl.getMessage(
          accountInfoConstants.REGISTRATION_DROPDOWN_DEFAULT_LABEL_PREFIX,
        )} 
       ${intl
         .getMessage(accountInfoConstants.ACCOUNT_CURRENCY_FIELD_LABEL)
         .toLocaleLowerCase()}
        `}
      />
    </ControllersWrapper>
  );
};

export default AdspAccountDetailsControllers;
