import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FieldValues } from 'react-hook-form';
import { useCreateAccountMutation } from '@services/account/account';
import {
  setIsAccountCreationFailed,
  setIsAccountCreationLoading,
} from 'src/shared/state/registration-slice';
import useAccountStatus from '@components/form/registration/hooks/useAccountStatus';
import {
  CreateAccountResponse,
  CreateAccountPayload,
} from '@services/account/types';
import { CaptchaResponse } from '@models/captcha';
import get from 'lodash/get';
import { AccountStatus } from 'src/shared/models/account';
import isEqual from 'lodash/isEqual';
import usePerformance from 'src/shared/hooks/usePerformance';

const useSubmit = () => {
  const dispatch = useDispatch();
  const [createAccount, { isLoading, isError }] = useCreateAccountMutation();
  const { start, end, cancel } = usePerformance({
    key: 'AccountRegistrationTime',
  });

  const onAccountCreationSuccess = (url: string, status: AccountStatus) => {
    isEqual(status, AccountStatus.Created) ? end() : cancel();
    window.location.assign(url);
  };

  const onAccountCreationFails = () => {
    cancel();
    dispatch(setIsAccountCreationLoading(false));
    dispatch(setIsAccountCreationFailed(true));
  };

  const { pollAccountStatus } = useAccountStatus(
    onAccountCreationSuccess,
    onAccountCreationFails,
  );

  /** Display the error banner or loading spinner in the page component **/
  useEffect(() => {
    if (isLoading) {
      dispatch(setIsAccountCreationLoading(true));
      dispatch(setIsAccountCreationFailed(false));
    }

    if (isError) {
      onAccountCreationFails();
    }
  }, [isLoading, isError, onAccountCreationFails, dispatch]);

  const onSubmit = async (
    accountFields: FieldValues,
    captchaResponse?: CaptchaResponse,
  ) => {
    const payload = { ...accountFields } as CreateAccountPayload;
    try {
      start();
      const { success }: CreateAccountResponse = await createAccount({
        data: payload,
        ...(captchaResponse && { captchaResponse }),
      }).unwrap();
      const advertiserAccountId = get(success, [
        0,
        'advertiserAccount',
        'advertiserAccountId',
      ]);

      if (!advertiserAccountId) {
        onAccountCreationFails();
      }

      //Start calling account status API
      pollAccountStatus(advertiserAccountId);
    } catch (e) {
      throw new Error();
    }
  };

  return {
    onSubmit,
  };
};

export default useSubmit;
