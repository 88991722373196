import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChromeConfig } from '@chrome/types';
import { getAccountSideNavigationItems } from '@chrome/configuration/sideNavigation';
import {
  ChromeKeys,
  HeaderTranslationKeys,
  MenuItemIds,
  PageLayoutType,
} from '@chrome/enums';
import { useGetConfigurationQuery } from '@services/configuration';
import useIntl from '@i18n/useIntl';
import { getAccountId } from '@utils/global';

const useChromeConfiguration = (
  key: ChromeKeys,
): Partial<ChromeConfig> | undefined => {
  const { intl } = useIntl();
  const { data: { entityId = '', entityName = '' } = {} } =
    useGetConfigurationQuery();
  const navigate = useNavigate();

  const configuration = useMemo(() => {
    return new Map([
      [
        ChromeKeys.AccountSettings,
        {
          header: {
            pageName: intl.getMessage(
              HeaderTranslationKeys.AccessAndSettingsPageName,
            ),
            name: entityName,
            value: getAccountId(entityId) || '',
            key: intl.getMessage(HeaderTranslationKeys.AccessAndSettingsKey),
          },
          sideNavigation: {
            items: getAccountSideNavigationItems(intl, entityId, navigate),
            selected: MenuItemIds.Account,
          },
          layout: { pageLayout: PageLayoutType.Standard },
        },
      ],
      [
        ChromeKeys.AccountRegistration,
        {
          layout: { pageLayout: PageLayoutType.Light },
          enableMarketplaceSwitcher: false,
        },
      ],
      [
        ChromeKeys.RetailerAccountRegistration,
        {
          layout: { pageLayout: PageLayoutType.Light },
          enableMarketplaceSwitcher: false,
        },
      ],
      [
        ChromeKeys.AdspAccountRegistration,
        {
          layout: { pageLayout: PageLayoutType.Light },
          //Todo set the page name translation when get it from product
          pageName: 'Create an Amazon DSP advertising account',
        },
      ],
      [
        ChromeKeys.IneligibilityRegistration,
        {
          //Todo set the page name translation when get it from product
          pageName: 'Registration ineligibility',
          layout: { pageLayout: PageLayoutType.Standard },
        },
      ],
    ]);
  }, [entityId, entityName, getAccountSideNavigationItems]);

  return configuration.get(key);
};

export default useChromeConfiguration;
