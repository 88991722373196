import React from 'react';
import Banner, { BannerProps } from '@components/notifications/banner/Banner';
import { BannerTypes } from '@components/notifications/enums';
import {
  BANNER_ERROR_ID_SUFFIX,
  BANNER_INFO_ID_SUFFIX,
  BANNER_SUCCESS_ID_SUFFIX,
  BANNER_WARNING_ID_SUFFIX,
  DEFAULT_BANNER_DURATION,
  DEFAULT_ERROR_BANNER_DURATION,
} from '@components/notifications/constants';

export const SuccessBanner = ({
  id,
  message,
  duration,
  children,
}: Partial<BannerProps>) => {
  return (
    <Banner
      id={`${id}-${BANNER_SUCCESS_ID_SUFFIX}`}
      props={{ type: BannerTypes.Success }}
      message={message}
      duration={duration !== undefined ? duration : DEFAULT_BANNER_DURATION}
    >
      {children}
    </Banner>
  );
};

export const ErrorBanner = ({
  id,
  message,
  duration,
  children,
}: Partial<BannerProps>) => {
  return (
    <Banner
      id={`${id}-${BANNER_ERROR_ID_SUFFIX}`}
      props={{ type: BannerTypes.Error }}
      message={message}
      duration={
        duration !== undefined ? duration : DEFAULT_ERROR_BANNER_DURATION
      }
    >
      {children}
    </Banner>
  );
};

export const WarningBanner = ({
  id,
  message,
  duration,
  children,
}: Partial<BannerProps>) => {
  return (
    <Banner
      id={`${id}-${BANNER_WARNING_ID_SUFFIX}`}
      props={{ type: BannerTypes.Warning, withCloseButton: true }}
      message={message}
      duration={duration !== undefined ? duration : 0}
    >
      {children}
    </Banner>
  );
};
export const InfoBanner = ({
  id,
  message,
  duration,
  children,
}: Partial<BannerProps>) => {
  return (
    <Banner
      id={`${id}-${BANNER_INFO_ID_SUFFIX}`}
      props={{ type: BannerTypes.Info, withCloseButton: true }}
      message={message}
      duration={duration !== undefined ? duration : 0}
    >
      {children}
    </Banner>
  );
};
