import { DEFAULT_LOCALE } from '../i18n/utils';

/**
 * Update the Document title with the pageName
 */
export const setPageTitle = (pageName = '') => {
  const separator = ' | ';
  const baseTitle =
    document.title.split(separator)[document.title.split(separator).length - 1];

  if (pageName) {
    document.title = `${pageName}${separator}${baseTitle}`;
  } else {
    document.title = baseTitle;
  }
};

export const convertLocale = (locale = DEFAULT_LOCALE) => {
  return locale.replace('_', '-');
};

export const getAccountId = (entityId: string) => {
  return entityId ? entityId.replace('ENTITY', 'A') : '';
};

/**
 *  Extracts the top-level domain (TLD) from a given URL string.
 *
 *  This function parses the URL and returns the TLD (e.g., "com" from "example.com").
 *  It handles both valid URLs and malformed URLs by using a fallback regex.
 *  Additionally, it excludes local addresses like 'localhost' or IP addresses
 */
export const getTLD = (url: string) => {
  try {
    const urlObject = new URL(url);
    // Exclude local addresses like 'localhost' or IP addresses
    if (
      urlObject.hostname.includes('localhost') ||
      /^(\d{1,3}\.){3}\d{1,3}$/.test(urlObject.hostname)
    ) {
      return 'com';
    }

    return urlObject.hostname.split('.').pop();
  } catch {
    const match = url.match(/\.([a-z]+)(?:\/|$)/i);
    return match ? match[1] : 'com';
  }
};
