import {
  ACCOUNT_STATUS_CREATED,
  ACCOUNT_STATUS_DISABLED,
  ACCOUNT_STATUS_PARTIALLY_CREATED,
  ACCOUNT_STATUS_PENDING,
} from 'src/pages/accountSettings/components/AccountDetails/constants';
import {
  AccountStatus,
  BillToType,
  Business,
  SellingAccount,
  SellingAccountType,
} from '@models/account';
import { MessageBundle } from '@amzn/arb-tools';
import {
  SELLING_ACCOUNTS_AUTHOR_TYPE,
  SELLING_ACCOUNTS_SELLER_TYPE,
  SELLING_ACCOUNTS_VENDOR_TYPE,
} from '@features/account-settings/constants';
import castArray from 'lodash/castArray';
import size from 'lodash/size';
import keyBy from 'lodash/keyBy';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import find from 'lodash/find';
import { LocationOption } from '@services/address';
import { FieldValues } from 'react-hook-form';
import { CreateAccountPayload } from '@services/account/types';
import { useDispatch } from 'react-redux';
import { accountApi } from '@services/account/account';

export const accountStatusTranslationKeys: Record<AccountStatus, string> = {
  [AccountStatus.Created]: ACCOUNT_STATUS_CREATED,
  [AccountStatus.Pending]: ACCOUNT_STATUS_PENDING,
  [AccountStatus.Disabled]: ACCOUNT_STATUS_DISABLED,
  [AccountStatus.PartiallyCreated]: ACCOUNT_STATUS_PARTIALLY_CREATED,
};

export const sellingAccountTypeTranslationKeys: Record<
  SellingAccountType,
  string
> = {
  AMAZON_VENDOR: SELLING_ACCOUNTS_VENDOR_TYPE,
  AMAZON_SELLER: SELLING_ACCOUNTS_SELLER_TYPE,
  AMAZON_AUTHOR: SELLING_ACCOUNTS_AUTHOR_TYPE,
};

export const SellingProgram: Record<SellingAccountType, string> = {
  AMAZON_SELLER: 'SELLER',
  AMAZON_VENDOR: 'VENDOR',
  AMAZON_AUTHOR: 'AUTHOR',
};

export const getTranslationKey = (
  value: string,
  translationKeys: Record<string, string>,
): string | undefined => {
  return translationKeys[value];
};

export const getSellingAccountType = (
  intl: MessageBundle,
  type: SellingAccountType,
): string => {
  const key = getTranslationKey(type, sellingAccountTypeTranslationKeys);
  if (!key) {
    console.error(`unable to translate selling account type ${type}`);
    return '';
  }
  return intl.getMessage(key);
};

export const getAccountStatus = (
  intl: MessageBundle,
  status: AccountStatus,
): string => {
  const key = getTranslationKey(status, accountStatusTranslationKeys);
  if (!key) {
    console.error(`unable to translate account status ${status}`);
    return intl.getMessage(
      getTranslationKey(AccountStatus.Pending, accountStatusTranslationKeys)!,
    );
  }
  return intl.getMessage(key);
};

const joinWithPipe = (arr: string[]): string => {
  return arr.filter((item) => item !== '' && item !== undefined).join(' | ');
};

export const getCountryCodeTranslation = (
  intl: MessageBundle,
  countryCode: string,
): string => {
  try {
    return intl.getMessage(countryCode);
  } catch (e) {
    console.error(`unable to translate countryCode ${countryCode}`, e);
    return '';
  }
};

export const formatCountryCodesWithPipe = (
  countryList: LocationOption[],
  countryCodes: string[] | string = [],
): string => {
  const countryCodeToDetailsMap = keyBy(countryList, 'value');
  return joinWithPipe(
    castArray(countryCodes).map((countryCode) =>
      get(countryCodeToDetailsMap, [countryCode, 'label'], countryCode),
    ),
  );
};

export const getAccountCreationRequestFromFormData = (
  data: FieldValues,
): CreateAccountPayload => {
  const { displayName, selectedSellingAccountId, sellingAccounts, business } =
    data;
  const {
    addresses, // This is passed when we get existing address
    businessName, // The individual address fields are passed when the user manually fills in an address
    zipCode,
    phone,
    website,
    city,
    countryCode,
    addressLine1,
    addressLine2,
    state,
    businessRegistrationNumber,
    selectedAddressId,
  } = business;

  let sellingAccount: SellingAccount | undefined = undefined;
  if (!isNil(selectedSellingAccountId)) {
    sellingAccount = find(sellingAccounts, {
      sellingAccountLinkToken: selectedSellingAccountId,
    });
  }

  // Temporary workaround for the demo, TODO fix by using the selected addressId
  let businessDetails = {} as Business;
  if (addresses && size(addresses) == 1) {
    const address = get(business, ['addresses', 0]);
    businessDetails = {
      businessName: address.businessName,
      zipCode: address.zipCode,
      phone: address.phone,
      website: address.website,
      city: address.city,
      countryCode: address.countryCode,
      addressLine1: address.addressLine1,
      addressLine2: address.addressLine2,
      state: address.state,
      businessRegistrationNumber,
    };
  } else {
    businessDetails = {
      businessName,
      zipCode,
      phone,
      website,
      city,
      countryCode,
      addressLine1,
      addressLine2,
      state,
      businessRegistrationNumber,
    };
  }

  return {
    displayName,
    ...(isNil(sellingAccount)
      ? {}
      : {
          sellingAccount: {
            sellingAccountId: selectedSellingAccountId,
            sellingAccountType: sellingAccount?.sellingProgram,
            countryCode: castArray(sellingAccount.countryCodes)[0],
          },
        }),
    ...(isNil(selectedAddressId)
      ? { business: businessDetails }
      : { addressToken: selectedAddressId }),
    billToType: BillToType.AdvertiserAccount,
  };
};

export const getSellingAccount = async (
  sellingAccountLinkToken: string,
  sellingProgram: SellingAccountType,
) => {
  const dispatch = useDispatch();

  const sellingAccountPromise = dispatch<any>(
    accountApi.endpoints.querySellingAccount.initiate({
      sellingAccountLinkToken,
      sellingProgram,
    }),
  );

  const { data } = await sellingAccountPromise;
  return get(data, ['sellingAccounts', 0]) as SellingAccount;
};
