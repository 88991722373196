import { combineReducers, configureStore } from '@reduxjs/toolkit';
import openRegistrationReducer from '../../pages/openRegistration/redux';
import marketplaceExpansionReducer from '../../pages/marketplaceExpansion/redux';
import termsReducer from '../../pages/terms/redux';
import sharedReducer from './redux';
import accountSettingsReducer from './account-settings-slice';
import registrationSliceReducer from './registration-slice';
import notificationReducer from './notifications-slice';
import { configurationApi } from '@services/configuration';
import { accountApi } from '@services/account/account';
import { registrationApi } from '@services/registration/registration';
import { addressApi } from '@services/address';
import { captchaApi } from '@services/captcha';

const rootReducer = combineReducers({
  openRegistration: openRegistrationReducer,
  marketplaceExpansion: marketplaceExpansionReducer,
  notification: notificationReducer,
  accountSettings: accountSettingsReducer,
  registration: registrationSliceReducer,
  shared: sharedReducer,
  terms: termsReducer,
  [configurationApi.reducerPath]: configurationApi.reducer,
  [accountApi.reducerPath]: accountApi.reducer,
  [registrationApi.reducerPath]: registrationApi.reducer,
  [captchaApi.reducerPath]: captchaApi.reducer,
  [addressApi.reducerPath]: addressApi.reducer,
});

export function setupStore(preloadedState?: Partial<RootState>) {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        configurationApi.middleware,
        accountApi.middleware,
        registrationApi.middleware,
        addressApi.middleware,
        captchaApi.middleware,
      ),
    preloadedState,
  });
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
