import React, { useCallback, useMemo } from 'react';
import { getTLD } from '@utils/global';
import useIntl from '@i18n/useIntl';
import useChrome from '@chrome/hooks/useChrome';
import { ChromeKeys } from '@chrome/enums';
import {
  INELIGIBILITY_REGISTRATION_CONTENT_TRANSLATION_KEY,
  INELIGIBILITY_REGISTRATION_PAGE_ID,
  INELIGIBILITY_REGISTRATION_SELLER_CENTRAL_TRANSLATION_KEY,
  INELIGIBILITY_REGISTRATION_TITLE_TRANSLATION_KEY,
  INELIGIBILITY_REGISTRATION_VENDOR_CENTRAL_TRANSLATION_KEY,
} from 'src/pages/IneligibilityRegistration/constants';
import {
  IneligibilityRegistrationLink,
  IneligibilityRegistrationPageContent,
  IneligibilityRegistrationPageWrapper,
  IneligibilityRegistrationText,
  IneligibilityRegistrationTitle,
} from 'src/pages/IneligibilityRegistration/style';

const IneligibilityRegistrationPage = () => {
  useChrome(ChromeKeys.IneligibilityRegistration);
  const { intl } = useIntl();

  const getURLs = useCallback(
    (origin: string) => {
      const tld = getTLD(origin);
      const sellerCentralURL = `https://sellercentral.amazon.${tld}/gp/help/help.html?itemID=43381&ref=id_43381_cont_2`;
      const vendorCentralURL = `https://vendorcentral.amazon.${tld}/hz/vendor/members/support/training/node/G202145430`;
      return { sellerCentralURL, vendorCentralURL };
    },
    [getTLD],
  );

  const { sellerCentralURL, vendorCentralURL } = useMemo(
    () => getURLs(window.location.origin),
    [getURLs],
  );

  return (
    <IneligibilityRegistrationPageWrapper
      id={INELIGIBILITY_REGISTRATION_PAGE_ID}
      data-testid={INELIGIBILITY_REGISTRATION_PAGE_ID}
    >
      <IneligibilityRegistrationPageContent>
        <IneligibilityRegistrationTitle type="h1">
          {intl.getMessage(INELIGIBILITY_REGISTRATION_TITLE_TRANSLATION_KEY)}
        </IneligibilityRegistrationTitle>
        <IneligibilityRegistrationText fontSize="base">
          {intl.formatMessage(
            INELIGIBILITY_REGISTRATION_CONTENT_TRANSLATION_KEY,
            {
              sellerCentral: (
                <IneligibilityRegistrationLink
                  href={sellerCentralURL}
                  key={sellerCentralURL}
                >
                  {intl.getMessage(
                    INELIGIBILITY_REGISTRATION_SELLER_CENTRAL_TRANSLATION_KEY,
                  )}
                </IneligibilityRegistrationLink>
              ),
              vendorCentral: (
                <IneligibilityRegistrationLink
                  href={vendorCentralURL}
                  key={vendorCentralURL}
                >
                  {intl.getMessage(
                    INELIGIBILITY_REGISTRATION_VENDOR_CENTRAL_TRANSLATION_KEY,
                  )}
                </IneligibilityRegistrationLink>
              ),
            },
          )}
        </IneligibilityRegistrationText>
      </IneligibilityRegistrationPageContent>
    </IneligibilityRegistrationPageWrapper>
  );
};

export default IneligibilityRegistrationPage;
