/**
 * Access and settings selectors
 */
export const INELIGIBILITY_REGISTRATION_PAGE_ID = 'ineligibility-registration';

/**
 * Translation Keys
 */
export const INELIGIBILITY_REGISTRATION_TITLE_TRANSLATION_KEY =
  'registration_ineligibility_title';
export const INELIGIBILITY_REGISTRATION_CONTENT_TRANSLATION_KEY =
  'registration_ineligibility_content';
export const INELIGIBILITY_REGISTRATION_SELLER_CENTRAL_TRANSLATION_KEY =
  'registration_ineligibility_seller_central';
export const INELIGIBILITY_REGISTRATION_VENDOR_CENTRAL_TRANSLATION_KEY =
  'registration_ineligibility_vendor_central';
