import React, { FC } from 'react';
import { createBrowserRouter, useSearchParams } from 'react-router-dom';
import Root from 'src/shared/Root';
import OpenRegistrationPage from '../pages/openRegistration/page';
import ManagerAccountRegistrationPage from '../pages/managerAccountRegistration/page';
import MarketplaceExpansionPage from '../pages/marketplaceExpansion/page';
import AgencyRegistrationPage from '../pages/agencyRegistration/page';
import TermsPage from '../pages/terms/termsPage';
import TermsChangesPage from '../pages/terms/termsChangesPage';
import TermsApprovalPage from '../pages/terms/termsAgreementPage';
import NotFoundPage from '../pages/errorPages/NotFoundPage';
import SauronProvider from './sauronProvider';
import AccountSettingsPage from 'src/pages/accountSettings/page';
import RegistrationPage from 'src/pages/registration/page';
import RetailerAccountRegistrationPage from 'src/pages/retailerAccountRegistration/page';
import AdspRegistrationPage from 'src/pages/adspRegistration/page';
import { ADSP_PROGRAM } from './constants';

// agency approval registration and marketplace expansion share a path in AUMS, so we must be backwards compatible
const CreateRouteMiddleware: FC = () => {
  const [searchParams] = useSearchParams();
  const selectedAccountForMarketplaceExpansion = searchParams.get(
    'selectedAccountForMarketplaceExpansion',
  );
  if (selectedAccountForMarketplaceExpansion) {
    return <MarketplaceExpansionPage />;
  }
  // This should return Agency Approval Registration page
  return <AgencyRegistrationPage />;
};

/**
 * We have two registration views: one for SAR and one for PLD registration.
 * Checks if the /registration endpoint has the query parameter 'invitationToken' and loads the appropriate component accordingly.
 */
const RegistrationRouter = () => {
  const [searchParams] = useSearchParams();
  const invitationToken = searchParams.get('invitationToken');
  const registrationProgram = searchParams.get('program');

  if (registrationProgram?.toLowerCase() === ADSP_PROGRAM) {
    return <AdspRegistrationPage />;
  }

  return invitationToken ? (
    <RetailerAccountRegistrationPage invitationToken={invitationToken} />
  ) : (
    <RegistrationPage />
  );
};

export const router = createBrowserRouter([
  {
    // This route will replace the SauronProvider route.
    // As some pages are still using the earlier implementation, the SauronProvider will not be removed yet.
    path: '/',
    element: <Root />,
    children: [
      {
        path: 'ads-account',
        children: [
          {
            path: 'registration',
            element: <RegistrationRouter />,
          },
          {
            path: ':entityId',
            children: [
              {
                path: 'account-settings',
                element: <AccountSettingsPage />,
              },
            ],
          },
        ],
      },
    ],
  },
  // These pages can be rendered without a CID, so we will not get sauron context
  {
    path: '/terms',
    element: <TermsPage />,
  },
  {
    path: '/sauron/terms',
    element: <TermsPage />,
  },
  {
    path: '/terms/changes',
    element: <TermsChangesPage />,
  },
  {
    path: '/sauron/terms/changes',
    element: <TermsChangesPage />,
  },

  // Most pages should belong here since they require Authentication and Sauron
  {
    path: '*',
    element: <SauronProvider />,
    children: [
      {
        path: 'advertisingAccounts/registration/create',
        element: <OpenRegistrationPage />,
      },
      {
        path: 'masterAccounts/create',
        element: <ManagerAccountRegistrationPage />,
      },
      {
        path: 'am/managerAccounts/create',
        element: <ManagerAccountRegistrationPage />,
      },
      {
        path: 'terms/agreement/:token',
        element: <TermsApprovalPage />,
      },
      {
        path: 'advertisingAccounts/create',
        element: <CreateRouteMiddleware />,
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
]);
