import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGetAccountStatusQuery } from '@services/account/account';
import {
  ACCOUNT_TYPE_MISMATCH_ERROR,
  NO_URL_FOUND_ERROR,
  POLLING_INTERVAL,
  POLLING_INTERVAL_END,
} from '@components/form/registration/constants';
import { AccountStatus } from '@models/account';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';

const useAccountStatus = (
  onSuccessCallback: (regionalEntityId: string, status: AccountStatus) => void,
  onFailureCallback: () => void,
) => {
  const dispatch = useDispatch();

  const [globalAccountId, setGlobalAccountId] = useState<string>('');
  const globalAccountStatus = useRef<AccountStatus>();
  const redirectionUrl = useRef<string>();
  const [timerId, setTimerId] = useState<ReturnType<typeof setTimeout>>();
  const [isFetchingAccountStatus, setIsFetchingAccountStatus] =
    useState<boolean>(false);

  const { data, isError } = useGetAccountStatusQuery(globalAccountId, {
    pollingInterval: POLLING_INTERVAL,
    skip: !isFetchingAccountStatus,
  });

  if (isError) {
    onFailureCallback();
  }

  const pollAccountStatus = (globalAccountId: string) => {
    setGlobalAccountId(globalAccountId);
    setIsFetchingAccountStatus(!!globalAccountId);
    const timeOutId = setTimeout(() => {
      setIsFetchingAccountStatus(false);
      if (
        isEqual(globalAccountStatus.current, AccountStatus.PartiallyCreated) &&
        redirectionUrl.current
      ) {
        onSuccessCallback(
          redirectionUrl.current,
          AccountStatus.PartiallyCreated,
        );
      } else {
        onFailureCallback();
      }
      clearTimeOut();
    }, POLLING_INTERVAL_END);
    setTimerId(timeOutId);
  };

  const clearTimeOut = useCallback(() => {
    clearTimeout(timerId);
  }, [timerId]);

  const accountStatusHandler = useCallback(
    (accountStatus: AccountStatus, url = '') => {
      switch (accountStatus) {
        case AccountStatus.Pending:
          break;
        case AccountStatus.PartiallyCreated:
          globalAccountStatus.current = accountStatus;
          if (!isNil(url)) {
            redirectionUrl.current = url;
          }
          break;
        case AccountStatus.Created:
          setIsFetchingAccountStatus(false);
          if (!url) {
            console.error(NO_URL_FOUND_ERROR);
            onFailureCallback();
          }
          onSuccessCallback(url, AccountStatus.Created);
          clearTimeOut();
          break;
        case AccountStatus.Disabled:
          setIsFetchingAccountStatus(false);
          onFailureCallback();
          clearTimeOut();
          break;
        default:
          console.error(ACCOUNT_TYPE_MISMATCH_ERROR);
          setIsFetchingAccountStatus(false);
          onFailureCallback();
          clearTimeOut();
          break;
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (data?.accountStatus) {
      accountStatusHandler(data.accountStatus, data?.url);
    }
  }, [data?.accountStatus]);

  return {
    pollAccountStatus,
  };
};

export default useAccountStatus;
