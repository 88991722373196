import styled from 'styled-components';
import { Text, Link } from '@amzn/storm-ui';

export const IneligibilityRegistrationPageWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

export const IneligibilityRegistrationPageContent = styled.div`
  max-width: 960px;
  padding: 20px 10px;
`;

export const IneligibilityRegistrationText = styled(Text)`
  font-size: 14px;
`;

export const IneligibilityRegistrationTitle = styled(Text)`
  font-weight: normal;
  font-size: 21px;
  margin-block-end: 18px;
  color: ${({ theme }) => theme.palette.orange['800']};
`;

export const IneligibilityRegistrationLink = styled(Link)`
  cursor: pointer;
  text-decoration: none !important;

  :hover {
    text-decoration: underline !important;
  }
`;
