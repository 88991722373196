import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from 'src/shared/api/api';
import {
  AccountRegistrationInfo,
  AdspAccountRegistrationInfo,
  RetailerAccountRegistrationInfo,
} from '@services/registration/types';

const BASE_URL = '/ads-account/api/v1/';
const GET_REGISTRATION_INFO_ENDPOINT = 'registration-info';
const GET_ADSP_REGISTRATION_INFO_ENDPOINT = 'registration-info/adsp';
const GET_RETAILER_ACCOUNT_REGISTRATION_INFO_ENDPOINT =
  'registration-retailer-info';
const REDUCER_PATH = 'RegistrationApi';

/**
 *  Registration Service
 */
export const registrationApi = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: axiosBaseQuery({
    baseUrl: BASE_URL,
  }),
  endpoints: (builder) => ({
    getRegistrationInfo: builder.query<AccountRegistrationInfo, void>({
      query: () => ({
        url: `${GET_REGISTRATION_INFO_ENDPOINT}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
    }),
    getAdspRegistrationInfo: builder.query<AdspAccountRegistrationInfo, void>({
      query: () => ({
        url: `${GET_ADSP_REGISTRATION_INFO_ENDPOINT}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
    }),
    getRetailerAccountRegistrationInfo: builder.query<
      RetailerAccountRegistrationInfo,
      string
    >({
      query: (token) => ({
        url: `${GET_RETAILER_ACCOUNT_REGISTRATION_INFO_ENDPOINT}/${token}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useGetRegistrationInfoQuery,
  useGetRetailerAccountRegistrationInfoQuery,
  useGetAdspRegistrationInfoQuery,
} = registrationApi;
