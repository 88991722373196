import React, { useState } from 'react';
import { Button, TextButton, Tooltip, Text, Link } from '@amzn/storm-ui';
import { FieldValues, useFormContext } from 'react-hook-form';
import useIntl from '@i18n/useIntl';
import { logEvent } from '@utils/takt';
import CaptchaModal from '@components/modals/CaptchaModal';
import ConfirmationModal from '@components/modals/ConfirmationModal';
import { Captcha, CaptchaResponse } from '@models/captcha';
import * as formFooterConst from './constants';

import {
  RegistrationPageFooterWrapper,
  RegistrationPageFooterActions,
  RegistrationPageFooterText,
} from './style';

interface RegistrationFormFooterProps {
  id: string;
  captcha?: Partial<Captcha>;
  onSubmit: (data: FieldValues, captchaResponse?: CaptchaResponse) => void;
  isTermsHidden?: boolean;
}

const RegistrationFormFooter = ({
  id,
  captcha,
  onSubmit,
  isTermsHidden,
}: RegistrationFormFooterProps) => {
  const { intl } = useIntl();
  const { formState, handleSubmit } = useFormContext();
  const [isCaptchaOpen, setIsCaptchaOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isValid = formState.isValid;

  const submitHandler = async () => {
    if (captcha && captcha?.captchaEnabled) {
      setIsCaptchaOpen(true);
    } else {
      setIsCaptchaOpen(false);
      await handleSubmit((data) => {
        onSubmit(data);
      })();
    }
  };

  const registrationCancellationHandler = () => {
    logEvent(`${id}-${formFooterConst.CANCEL_BUTTON}`);
    history.back();
  };

  return (
    <RegistrationPageFooterWrapper>
      {isTermsHidden ? null : (
        <RegistrationPageFooterText>
          <Text>
            {intl.getMessage(
              formFooterConst.REGISTRATION_FORM_FOOTER_MESSAGE_TRANSLATION_KEY,
            )}
          </Text>
          <Link
            href={formFooterConst.TERMS_PAGE_URL}
            size={'base'}
            target="_blank"
          >
            {intl.getMessage(
              formFooterConst.REGISTRATION_FORM_FOOTER_LINK_TRANSLATION_KEY,
            )}
          </Link>
        </RegistrationPageFooterText>
      )}
      <RegistrationPageFooterActions>
        <TextButton
          id={`${id}-${formFooterConst.CANCEL_BUTTON}`}
          onClick={() => setIsModalOpen(true)}
        >
          {intl.getMessage(
            formFooterConst.REGISTRATION_FORM_FOOTER_CANCEL_BUTTON_TRANSLATION_KEY,
          )}
        </TextButton>
        {!isValid && (
          <Tooltip
            trigger={
              <div>
                <Button
                  primary
                  disabled={!isValid}
                  id={`${id}-${formFooterConst.SUBMIT_BUTTON}`}
                >
                  {intl.getMessage(
                    isTermsHidden
                      ? formFooterConst.ADSP_REGISTRATION_FORM_FOOTER_SUBMIT_BUTTON_TRANSLATION_KEY
                      : formFooterConst.REGISTRATION_FORM_FOOTER_SUBMIT_BUTTON_TRANSLATION_KEY,
                  )}
                </Button>
              </div>
            }
            message={intl.getMessage(
              formFooterConst.REGISTRATION_FORM_FOOTER_TOOLTIP_MESSAGE_TRANSLATION_KEY,
            )}
            type="dark"
          />
        )}
        {isValid && (
          <Button
            id={`${id}-${formFooterConst.SUBMIT_BUTTON}`}
            taktId={`${id}-${formFooterConst.SUBMIT_BUTTON}`}
            primary
            onClick={async () => {
              await submitHandler();
            }}
          >
            {intl.getMessage(
              formFooterConst.REGISTRATION_FORM_FOOTER_SUBMIT_BUTTON_TRANSLATION_KEY,
            )}
          </Button>
        )}
      </RegistrationPageFooterActions>
      <CaptchaModal
        id={`${id}-${formFooterConst.CAPTCHA_MODAL}`}
        isOpen={isCaptchaOpen}
        captchaOptions={{
          ...captcha,
          'data-event': new CustomEvent('customEvent'),
          'data-iframe-id': `${id}-${formFooterConst.CHALLENGE_IFRAME}`,
          'data-modal': false,
          'data-callback': async (captchaResponse: CaptchaResponse) => {
            if (captchaResponse) {
              setIsCaptchaOpen(false);
              await handleSubmit((formData) =>
                onSubmit(formData, captchaResponse),
              )();
            }
          },
        }}
      />
      <ConfirmationModal
        id={formFooterConst.CANCEL_REGISTRATION_CONFIRM_MODAL_ID}
        header={
          <Text fontSize={'medium'}>
            {intl.getMessage(
              formFooterConst.CANCEL_REGISTRATION_CONFIRMATION_MODAL_HEADER_TRANSLATION_KEY,
            )}
          </Text>
        }
        isOpen={isModalOpen}
        cancelButtonText={intl.getMessage(
          formFooterConst.CANCEL_REGISTRATION_CONFIRMATION_MODAL_CANCEL_BUTTON_TRANSLATION_KEY,
        )}
        confirmButtonText={intl.getMessage(
          formFooterConst.CANCEL_REGISTRATION_CONFIRMATION_MODAL_CONFIRM_BUTTON_TRANSLATION_KEY,
        )}
        onConfirm={registrationCancellationHandler}
        onCancel={() => setIsModalOpen(false)}
      >
        <Text fontSize={'base'}>
          {intl.getMessage(
            formFooterConst.CANCEL_REGISTRATION_CONFIRMATION_MODAL_MESSAGE_TRANSLATION_KEY,
          )}
        </Text>
      </ConfirmationModal>
    </RegistrationPageFooterWrapper>
  );
};

export default RegistrationFormFooter;
