import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { CardHeader, Text } from '@amzn/storm-ui';
import useIntl from '@i18n/useIntl';
import { Captcha } from '@models/captcha';
import { handleKeyDown } from '@components/form/utils';
import AdspAccountDetailsControllers from 'src/pages/adspRegistration/components/AdspAccountDetailsControllers';
import useSubmit from '@features/account-registration/hooks/useSubmit';
import RegistrationUserDetails from '@features/account-registration/components/RegistrationUserDetails/RegistrationUserDetails';
import BusinessDetails from '@features/account-registration/components/BusinessDetails/BusinessDetails';
import RegistrationFormFooter from '@features/account-registration/components/RegistrationFooter/RegistrationFormFooter';
import { ADSP_REGISTRATION_PAGE_ID } from 'src/pages/adspRegistration/constants';
import { REGISTRATION_FORM_TITLE_TRANSLATION_KEY } from '@features/account-registration/constants';
import {
  RegistrationCardContent,
  RegistrationCustomDivider,
  RegistrationFormCard,
  RegistrationFormWrapper,
} from '@features/account-registration/style';

interface AdspRegistrationFormProps {
  userName: string;
  switchAccountUrl: string;
  emailAddress: string;
  industries: Array<{ value: string; label: string }>;
  currencies: Array<{ value: string; label: string }>;
  timeZones: Array<{ value: string; label: string }>;
  captcha?: Partial<Captcha>;
}

const AdspRegistrationForm = ({
  userName,
  emailAddress,
  switchAccountUrl,
  industries,
  currencies,
  timeZones,
  captcha = {},
}: AdspRegistrationFormProps) => {
  const { intl } = useIntl();
  const { onSubmit } = useSubmit();

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: {
      displayName: '',
      selectedSellingAccountId: null,
      sellingAccounts: [],
      industry: '',
      currency: '',
      timeZone: '',
      business: {
        selectedAddressId: null,
        businessName: '',
        phone: '',
        website: '',
        countryCode: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zipCode: '',
        businessRegistrationNumber: '',
      },
    },
  });

  return (
    <FormProvider {...methods}>
      <RegistrationFormWrapper onKeyDown={handleKeyDown}>
        <RegistrationFormCard>
          <RegistrationUserDetails
            userName={userName}
            emailAddress={emailAddress}
            switchAccountUrl={switchAccountUrl}
          />
        </RegistrationFormCard>
        <RegistrationFormCard>
          <CardHeader padding="large">
            <Text type="h2" fontSize="large">
              {intl.getMessage(REGISTRATION_FORM_TITLE_TRANSLATION_KEY)}
            </Text>
          </CardHeader>
          <BusinessDetails />
          <RegistrationCustomDivider />
          <RegistrationCardContent>
            <AdspAccountDetailsControllers
              industries={industries}
              currencies={currencies}
              timeZones={timeZones}
            />
          </RegistrationCardContent>
        </RegistrationFormCard>
      </RegistrationFormWrapper>
      <RegistrationFormFooter
        id={ADSP_REGISTRATION_PAGE_ID}
        captcha={captcha}
        onSubmit={onSubmit}
        isTermsHidden={true}
      />
    </FormProvider>
  );
};

export default AdspRegistrationForm;
