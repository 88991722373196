import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useIntl from '@i18n/useIntl';
import { useGetAdspRegistrationInfoQuery } from '@services/registration/registration';
import Spinner, { SpinnerSize } from '@components/Spinner';
import AdspRegistrationForm from 'src/pages/adspRegistration/components/AdspRegistrationForm';
import { RootState } from 'src/shared/state/store';
import RegistrationTitle from '@features/account-registration/components/RegistrationTitle';
import RegistrationGeneralErrorBanner from '@features/account-registration/components/RegistrationGeneralErrorBanner';
import { REGISTRATION_CREATE_ACCOUNT_SPINNER_MESSAGE_TRANSLATION_KEY } from '@features/account-registration/constants';
import {
  ADSP_REGISTRATION_CREATE_ACCOUNT_BANNER_ID,
  ADSP_REGISTRATION_PAGE_ID,
  ADSP_REGISTRATION_TITLE_TRANSLATION_KEY,
} from 'src/pages/adspRegistration/constants';
import {
  AdspRegistrationPageInnerWrapper,
  AdspRegistrationPageWrapper,
} from 'src/pages/adspRegistration/style';
import useChrome from 'src/shared/chrome/hooks/useChrome';
import { ChromeKeys } from '@chrome/enums';

const AdspRegistrationPage = () => {
  const { intl } = useIntl();
  useChrome(ChromeKeys.AdspAccountRegistration);

  const {
    data: registrationInfo,
    isLoading,
    isError,
  } = useGetAdspRegistrationInfoQuery();

  const isAccountCreationLoading = useSelector(
    (state: RootState) => state.registration.isAccountCreationInProgress,
  );

  const isAccountCreationFailed = useSelector(
    (state: RootState) => state.registration.isAccountCreationFailed,
  );

  useEffect(() => {
    if (isAccountCreationFailed) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [isAccountCreationFailed]);

  if (isLoading) {
    return <Spinner size={SpinnerSize.large} />;
  }

  if (isError) {
    return <RegistrationGeneralErrorBanner id={ADSP_REGISTRATION_PAGE_ID} />;
  }

  return (
    <>
      {registrationInfo && (
        <AdspRegistrationPageWrapper
          id={ADSP_REGISTRATION_PAGE_ID}
          data-testid={ADSP_REGISTRATION_PAGE_ID}
        >
          <AdspRegistrationPageInnerWrapper>
            {isAccountCreationLoading && (
              <Spinner
                isFullScreen={true}
                text={intl.getMessage(
                  REGISTRATION_CREATE_ACCOUNT_SPINNER_MESSAGE_TRANSLATION_KEY,
                )}
                size={SpinnerSize.large}
                withBackground={true}
              />
            )}
            {isAccountCreationFailed && (
              <RegistrationGeneralErrorBanner
                id={ADSP_REGISTRATION_CREATE_ACCOUNT_BANNER_ID}
              />
            )}
            <RegistrationTitle
              title={intl.getMessage(ADSP_REGISTRATION_TITLE_TRANSLATION_KEY)}
            />
            <AdspRegistrationForm {...registrationInfo} />
          </AdspRegistrationPageInnerWrapper>
        </AdspRegistrationPageWrapper>
      )}
    </>
  );
};

export default AdspRegistrationPage;
