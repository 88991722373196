import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Alert, AlertProps, Text } from '@amzn/storm-ui';
import {
  BANNER_CLASS_NAME,
  BANNER_INIT_ERROR,
  DEFAULT_BANNER_DURATION,
} from '@components/notifications/constants';
import styled from 'styled-components';
import useDuration from '@components/notifications/hook/useDuration';

const AlertWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.large};
`;

export interface BannerProps {
  id: string;
  props: Partial<AlertProps>;
  /** Optional - element for more complex cases */
  children?: ReactElement;
  /** Optional - banner message */
  message?: string;
  duration?: number;
  /** Optional - custom callback in case we want to run function when closing the banner */
  customOnCloseCallback?: () => void;
}

const Banner = ({
  id,
  props,
  children,
  message,
  duration = DEFAULT_BANNER_DURATION,
  customOnCloseCallback,
}: BannerProps): ReactElement | null => {
  const [open, setOpen] = useState<boolean>(true);

  const onClose = useCallback(() => {
    setOpen(false);
    if (customOnCloseCallback) {
      customOnCloseCallback();
    }
  }, []);

  useDuration(duration, () => {
    if (!open) {
      return;
    }

    onClose();
  });

  useEffect(() => {
    if (!message && !children) {
      console.error(BANNER_INIT_ERROR);
    }
  }, []);

  return (
    <AlertWrapper>
      <Alert
        data-testid={id}
        className={BANNER_CLASS_NAME}
        {...props}
        isOpen={open}
        onClose={onClose}
      >
        <div>
          {message && <Text>{message}</Text>}
          {children && children}
        </div>
      </Alert>
    </AlertWrapper>
  );
};

export default Banner;
