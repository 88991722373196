import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from './state/store';
import { fetchSauronContext } from './state/redux';
import LocaleProvider from './intl';
import ChromeProvider from './chrome';
import { Outlet, useLocation } from 'react-router-dom';

const SauronProvider: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  console.log('Current path:', location.pathname);
  console.log('Full location:', location);

  // fetch shared resources for all Pages
  useEffect(() => {
    dispatch(fetchSauronContext());
  }, []);

  /* put providers here that are related to shared state that requires api calls.
      notably, components in providers need locales for translations */
  return (
    <LocaleProvider>
      <ChromeProvider>
        <Outlet />
      </ChromeProvider>
    </LocaleProvider>
  );
};

export default SauronProvider;
